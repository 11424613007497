import React from "react"
import { FaHeart } from 'react-icons/fa'
import Image from "../../wal-common/src/components/Image/Image"
import "./HeaderWithFoto.scss"

const HeaderWithFoto = () => {
  return (
    <div className="Title">
      <Image file="familie2.JPG" style={{
        width: '100%',
        height: '100%'
      }} />

      <div className="Title__Unten">
        <svg className="Title__Svg">
          <path
            className="Title__Path"
            id="curve"
            d="M 15 100 Q 115 50 215 100 Z"
          />
          <text className="Title__Text">
            <textPath xlinkHref="#curve">Chirkovs</textPath>
          </text>
        </svg>

        <FaHeart className="Title__Heart" />

        <div className="Title__Slogan m-b-md">
          together forever
        </div>
      </div>
    </div>
  )
}

export default HeaderWithFoto
