import React from "react"
import './styles.scss'
import HeaderWithFoto from "../components/HeaderWithFoto/headerWithFoto"
import Menu from "../components/Menu/Menu"

const Index = () => {
  return (
    <div>
      <HeaderWithFoto />
      <Menu />
    </div>
  )
}

export default Index