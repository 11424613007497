import React from "react"
import './Menu.scss'

const Menu = () => {
  return (
    <div className="Menu section">
      <ul className="menu-list">
        <li><a href="https://www.mnushi.de">Mama's work</a></li>
        <li><a href="https://alexander.chirkov.de">Papa's work</a></li>
      </ul>
    </div>
  )
}

export default Menu